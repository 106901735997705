import type { UseSeoMetaInput } from '@unhead/schema'
import type { PageMetadataDocument } from '@/types/prismic.generated'
import consts from '@/consts'

export default function (pageData: PageMetadataDocument['data'] | null): UseSeoMetaInput {
  if (pageData === null) {
    // Likely there is no "page_metadata" document defined in Prismic for this page.
    console.warn('No Metadata defined for this page.')
    return {}
  } else {
    let pageTitle = ''

    if (pageData?.page_title && pageData.page_title_postfix) {
      pageTitle = `${pageData.page_title} · ${pageData.page_title_postfix}`
    } else if (pageData?.page_title) {
      pageTitle = pageData.page_title
    }

    return {
      title: pageTitle,
      description: pageData.page_description,
      ogTitle: pageData.og_title,
      ogDescription: pageData.og_description,
      ogImage: pageData.meta_image.url ? pageData.meta_image.url : consts.defaultMetaImageUrl,
      ogImageAlt: pageData.page_title,
      twitterTitle: pageData.twitter_title,
      twitterDescription: pageData.twitter_description,
      twitterImage: pageData.meta_image.url ? pageData.meta_image.url : consts.defaultMetaImageUrl,
    }
  }
}
