import * as prismic from '@prismicio/client'

import type { PageMetadataDocument } from '@/types/prismic.generated'

export default async function (): Promise<PageMetadataDocument['data'] | null> {
  const { client } = usePrismic()
  const defaultPrismicQueryParams = useDefaultPrismicQueryParams()
  const routeName = useRoute()?.name ? String(useRoute().name).split('___')[0] : null
  if (typeof routeName === 'string') {
    const { data: metadata } = await useAsyncData('metadata', () =>
      client.getByType('page_metadata', {
        ...defaultPrismicQueryParams,
        ...{
          filters: [prismic.filter.at('my.page_metadata.page', routeName)],
        },
      }),
    )
    return metadata?.value?.results[0]?.data ? metadata.value.results[0].data : null
  } else {
    return null
  }
}
